@font-face {
    font-family: 'COMICSANS';
    src: url('./fonts/COMICSANS.TTF') format('truetype');
}

@font-face {
    font-family: 'Sekate';
    src: url('./fonts/Sekate.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: 'Sekate', Arial, sans-serif;
}

/* .slick-list {
    margin: 0 0 0 10px !important;
}

@media (max-width: 600px) {
    .slick-list {
        margin: 0 10px 0 30px !important;
    }
} */

.slick-track {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 12px;
}